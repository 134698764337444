<template>
	<div class="dropdown-menu" v-loading="notificationsLoading">
		<div class="media-list media-list-hover media-list-divided media-list-xs" v-on:scroll="isScrolling">
			<div v-if="list.length > 0">
				<a @click="markNotificationAsSeen(n)" class="media" :class="{'media-new': n.seen == 0}"
					v-for="(n, i) in list" :key="i">
					<span class="avatar bg-success">
						<svg-icon :icon-class="notificationTypeIcon(n.type)" />
					</span>
					<div class="media-body">
						<p v-html="notificationsExcerpt(n.message)"></p>
						<time>{{$moment(n.date, "YYYY-MM-DD hh:mm:ss").fromNow()}}</time>
					</div>
				</a>
			</div>
			<div v-else>
				<span>Nu aveti notificari</span>
			</div>
		</div>

		<div class="dropdown-footer">
			<div class="left">
				<a @click="markCurrentListAsSeen">Marcheaza ca fiind citite</a>
			</div>

			<div class="right">
				<svg-icon @click.native="getLatestNotifications" class="footer-icons" title="Actualizeaza"
					icon-class="rotate-right" />
				<!-- <svg-icon class="footer-icons" title="Setari" icon-class="cog" /> -->
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters,
		mapState
	} from 'vuex';

	export default {
		name: 'Notifications',
		data() {
			return {
				delay: false
			};
		},
		methods: {
			notificationTypeIcon(type) {
				switch (type) {
					case 'communication':
						return 'headset';

					case 'products':
						return 'theme';

					case 'orders':
						return 'cart';

					case 'docs':
						return 'file-text';

					case 'payments':
						return 'coins';

					default:
						return 'bell';
				}
			},
			async markCurrentListAsSeen() {
				try {
					if (!this.unreadNotifications) return false;
					await this.$store.dispatch('notifications/MarkCurrentListAsSeen');
				} catch (err) {
					console.log(err);
				}
			},
			async markNotificationAsSeen(n) {
				try {
					if (n.seen == 0) {
						await this.$store.dispatch('notifications/MarkNotificationAsSeen', n.id);
					}
					this.$router.push(n.href.replace('#', '')).catch(() => {});
				} catch (err) {
					console.log(err);
				}
			},
			async getLatestNotifications() {
				if (this.delay) return false;
				this.delay = true;
				try {
					await this.$store.dispatch('notifications/GetLatestNotifications');
					setTimeout(() => {
						this.delay = false;
					}, 2000);
				} catch (err) {
					console.log(err);
				}
			},
			async isScrolling(el) {
				const target = el.target;
				if (Math.floor(target.scrollTop / (target.scrollHeight - target.clientHeight) * 100) >= 90 && !this
					.finishedList) {
					await this.$store.dispatch('notifications/GetNotificationsList');
				}
			},
			notificationsExcerpt(message) {
				return message.length > 80 ? message.substring(0, 77) + '...' : message;
			}
		},
		computed: {
			...mapState({
				'list': (state) => state.notifications.list,
				'opened': (state) => state.notifications.opened,
				'notificationsLoading': (state) => state.notifications.loading,
				'finishedList': (state) => state.notifications.finishedList
			}),
			...mapGetters({
				'unreadNotifications': 'notifications/unreadNotifications'
			})
		},
		watch: {
			async opened(status) {
				// if (status && this.unreadNotifications > 0) await this.$store.dispatch(
				// 	'notifications/MarkcurrentListAsSeen')
			}
		},
		mounted() {}
	};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
	.dropdown-menu {
		font-size: 13px;
		position: fixed;
		background-color: #FFF;
		z-index: 2019;
		right: 86px;
		border: 1px solid rgba(235, 235, 235, 0.4);
		border-top: 0;
		line-height: 1.325rem;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.06);
		float: left;
		text-align: left;
		list-style: none;
		min-width: 180px;
		max-width: 420px;
		margin-top: 70px;

		&:before {
			content: '';
			position: absolute;
			top: -14px;
			right: 44px;
			width: 0;
			height: 0;
			border-left: 11px solid transparent;
			border-right: 11px solid transparent;
			border-bottom: 14px solid rgba(255, 255, 255, 0);
		}

		&:after {
			border-bottom-color: #fdfeff;
			content: '';
			top: -14px;
			position: absolute;
			right: 44px;
			width: 0;
			height: 0;
			border-left: 11px solid transparent;
			border-right: 11px solid transparent;
			border-bottom: 14px solid rgb(255, 255, 255);
		}

		.media-list {
			width: 420px !important;
			max-height: 350px;
			overflow-y: scroll;
		}

		.media:last-child {
			border-bottom: 0;
		}

		.media {
			border-bottom: 1px solid rgba(77, 82, 89, 0.07);
			margin-bottom: 0;
			padding: 10px 16px;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: start;
			align-items: flex-start;

			&:hover {
				background-color: #eaf1f9d6 !important;
			}

			&.media-new {
				background-color: #f3f9ff;
			}
		}

		.avatar {
			flex-shrink: 0;
			background-color: #9E9E9E;
			color: #fff;
			position: relative;
			display: inline-block;
			width: 36px;
			height: 36px;
			line-height: 36px;
			text-align: center;
			border-radius: 100%;
		}

		.media-body {
			min-width: 0;
			-ms-flex: 1;
			flex: 1;
		}

		p {
			margin: 0;
		}

		time {
			margin-bottom: 0;
			font-size: 12px;
			color: #8b95a5;
			font-family: Roboto, sans-serif;
			font-weight: 400;
			font-size: 11px;
			color: #8b95a5;
		}

		.media>* {
			margin: 0 8px;
		}

		.dropdown-footer {
			display: -webkit-box;
			display: flex;
			-webkit-box-pack: justify;
			justify-content: space-between;
			background-color: #f9fafb;
			padding: 0 0.75rem;
		}

		.dropdown-footer a {
			display: inline-block;
			padding: 10px 8px;
			color: #8b95a5;
		}

		.dropdown-footer a:hover,
		.dropdown-footer i:hover {
			color: #4d5259;
		}

		.svg-icon {
			width: auto;
			height: auto;
		}

		.dropdown-footer .footer-icons {
			display: inline-block;
			padding: 10px 8px;
			color: #8b95a5;
			cursor: pointer;
			line-height: 1.325rem;
		}
	}

	@media (max-width: 500px) {
		.dropdown-menu {
			min-width: 180px;
			max-width: 100% !important;
			width: 100%;
			width: 100% !important;
			left: 0;
			right: 0;

			.media-list {
				width: 100% !important;
			}

			&:after {
				right: 130px;
			}

			&:before {
				right: 130px;
			}
		}
	}
</style>